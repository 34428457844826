<template>
  <div class="marketing-push">
    <div class="information-container">
      <div v-if="geographicZoneSlug" class="continent-bubble">
        <Icon
          v-if="geographicZoneIcon"
          :name="geographicZoneIcon"
          fill="var(--color-gold)"
          class="h-[46px] w-[46px] sm:h-[37px] sm:w-[37px]"
        />
      </div>
      <TwicImg ratio="4:1" class="logo" :src="logo" />
      <span class="title">{{ title }}</span>
      <div class="short-description">{{ subtitle }}</div>
      <NuxtLink class="discover" :href="cta.url">
        {{ cta.label }}
      </NuxtLink>
      <div class="border-box-top-left"></div>
      <div class="border-box-top-right"></div>
      <div class="border-box-bottom-left"></div>
      <div class="border-box-bottom-right"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getGeographicZonePicto } from '@/lib/getGeographicZonePicto';

import { Cta } from '@/lib/strapi-types/components/Cta';

const { geographicZoneSlug } = defineProps<{
  geographicZoneSlug?: string;
  logo: string;
  title: string;
  subtitle: string;
  cta: Cta;
}>();

const geographicZoneIcon = geographicZoneSlug
  ? getGeographicZonePicto({ geographicZoneSlug })
  : undefined;
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/typography.scss';
@use '$/breakpoints.scss';

.marketing-push {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 90px 0;

  border-radius: border-radius.$small;

  transition: 0.5s;

  .information-container {
    position: relative;
    z-index: 3;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 510px;
    height: 100%;
    padding: 24px;

    color: white;
    text-align: center;

    backdrop-filter: blur(5px);

    animation: fadein 0.2s;

    .continent-bubble {
      position: absolute;
      top: -40px;

      align-items: center;
      justify-content: center;

      width: 80px;
      height: 80px;

      background-color: white;
      border-radius: 100%;
    }

    .logo {
      width: 130px;
      height: 40px;
      margin-bottom: 40px;
      object-fit: cover;
    }

    .title {
      font-size: typography.$desktop-h2;
      font-weight: 600;
    }

    .short-description {
      margin-bottom: 40px;
      font-size: typography.$desktop-body-xl;
    }

    .discover {
      @include button.outlined(white, $color-background: none, $color-hover: colors.$green);

      &:hover {
        border: solid 1px colors.$green;
      }
    }
  }

  .border-box-top-left {
    position: absolute;
    top: -1px;
    left: -1px;

    width: 30px;
    height: 30px;

    border-top: solid 1px white;
    border-left: solid 1px white;
  }

  .border-box-top-right {
    position: absolute;
    top: -1px;
    right: -1px;

    width: 30px;
    height: 30px;

    border-top: solid 1px white;
    border-right: solid 1px white;
  }

  .border-box-bottom-left {
    position: absolute;
    bottom: -1px;
    left: -1px;

    width: 30px;
    height: 30px;

    border-bottom: solid 1px white;
    border-left: solid 1px white;
  }

  .border-box-bottom-right {
    position: absolute;
    right: -1px;
    bottom: -1px;

    width: 30px;
    height: 30px;

    border-right: solid 1px white;
    border-bottom: solid 1px white;
  }
}

@include breakpoints.mobile() {
  .marketing-push {
    padding: 80px 16px 59px;

    .information-container {
      justify-content: center;

      width: 100%;
      height: 100%;
      padding: 0;

      border-radius: border-radius.$small;

      .continent-bubble {
        top: -32px;
        width: 64px;
        height: 64px;
      }

      .title {
        font-size: typography.$mobile-h2;
      }

      .short-description {
        margin-bottom: 8px;
        font-size: typography.$mobile-body-l;
      }

      .discover {
        @include button.outlined(
          $color: white,
          $color-background: none,
          $color-hover: colors.$green
        );
      }
    }
  }
}
</style>
