import { stripNewLines } from '../utils';

export const homeMockRatings = {
  count: 42,
  average: 4,
  ratings: [
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectatur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectetur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                  mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                  facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    }
  ]
};
